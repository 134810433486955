<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span class="vehicle-list-title" @click.stop="toggleList">
              {{ $t('menu.traffic_signs') }}
            </span>
            <div class="vehicle-list-caret" :class="{ caret_open: listOpen }" @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
                    <b-row>
                        <b-badge
                            v-for="item in timeFilters"
                            :key="item.value"
                            @click="toggleTimeFilter(item.value)"
                            :variant="isActiveTimeFilter(item.value) ? 'primary' : 'light'"
                            class="mr-2 mb-2"
                            style="padding: .5em 1em"
                        >
                            {{ item.text }}
                        </b-badge>
                    </b-row>
                    <span class="span-title white-text">{{ $t('road_sign.condition') }}</span>
                    <b-row v-bind:class="{ 'multiselect-expand': multiselectOpen }">
                        <multiselect
                            v-model="condition"
                            :placeholder="$t('road_sign.select_sign')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            :multiple="true"
                            label="name"
                            track-by="value"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="getConditions()"
                            :open-direction="'below'"
                            :max-height="300"
                            @open="multiselectOpen = true"
                            @close="multiselectOpen = false"
                        >
                            <template slot="singleLabel" slot-scope="props">
                                <b-row class="nopads">
                                    <b-col style="padding-top: 2px;">
                                        <span class="option__title" style="font-weight: 400">{{ props.option.name }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <template slot="option" slot-scope="props">
                                <b-row class="nopads">
                                    <b-col class="pt-2">
                                        <span class="option__title pt-2">{{ props.option.name }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {restApi} from '../mixins/RestApiMixin'
import {roadSignHelper, roadSignTypes} from "@/components/mixins/RoadSignMixin";
const STATUS = {
    ALL: 0,
    ACTIVE: 1,
    DELETED: 2,
}
// Allow selection of multiple time filters
// const INIT_STATUS = [STATUS.ALL]
const INIT_STATUS = STATUS.ACTIVE
export default {
    name: "road-sign-filter",
    mixins: [restApi, roadSignHelper, roadSignTypes],
    data() {
        return {
            isOpen: true,
            loading: false,
            listOpen: false,
            multiselectOpen: false,
            condition: [],
            selectedTimeFilters: INIT_STATUS,
            timeFilters: [
                {
                    text: this.$t("common.all"),
                    value: STATUS.ALL,
                },
                {
                    text: this.$t("common.active"),
                    value: STATUS.ACTIVE,
                },
                {
                    text: this.$t("common.closed"),
                    value: STATUS.DELETED,
                },
            ],
        };
    },
    methods: {
        toggleTimeFilter(val) {
            this.selectedTimeFilters = val
        },
        isActiveTimeFilter(val) {
            return val === this.selectedTimeFilters
        },
        toggleList: function () {
            this.listOpen = !this.listOpen
        },
    },
    computed: {
        amountOfFiltersSelected() {
            return (this.selectedTimeFilters ? 1 : 0) + (this.selectedContractId ? 1 : 0)
        }
    },
    watch: {
        selectedTimeFilters(val) {
            this.$emit('filtersUpdated', val)
        },
        condition(val) {
            this.$emit('filtersUpdated', this.selectedTimeFilters, val)
        }
    },
}
</script>
<style lang="scss" scoped>
.badge-filters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex: 5;
    flex-wrap: wrap;
    & > * {
        cursor: pointer;
    }
    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
}

.multiselect-expand {
    height: 300px;
    vertical-align: top;
}

</style>

