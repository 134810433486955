<template>
    <div v-if="trips && trips.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title pb-0" style="display: block">{{ $t('daily_summary.title') }} ({{trips.length}})</span>
                <span class="tip-light">{{ $t('contract_reports.trips_hint') }}</span>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : tripsVisible}"
                    v-b-toggle.tripDetails
                    @click.stop="tripsVisible = !tripsVisible"/>
            </b-col>
        </b-row>
        <contract-trip-summary-charts
            ref="charts"
            :results="trips"
            class="show-print"
            :class="{'hidden-charts': !tripsVisible}"
        />
        <b-collapse
            id="tripDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <contract-trip-summary-list
                :summaries="trips"
                :params="tripParams"
                @rowSelected="showTripDetails"
                @loading="tripsLoading"
            />
        <contract-trip-details
            v-if="tripDetailsRow"
            @close="tripDetailsRow = null"
            :summaryItem="tripDetailsRow"
            :tripTable="trips"
        />
        </b-collapse>
        <hr class="report-divider nopads"/>

    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import ContractTripSummaryList from "@/components/tripsummary/ContractTripSummaryList";
import ContractTripSummaryCharts from "@/components/tripsummary/ContractTripSummaryCharts";
import {restApi} from "@/components/mixins/RestApiMixin";
import ContractTripDetails from "../tripsummary/ContractTripDetails";

export default {
    name: "contract-report-trips",
    mixins: [timeUtils, restApi],
    components: {ContractTripDetails, ContractTripSummaryCharts, ContractTripSummaryList},
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            tripsVisible: false,
            unit: '',
            trips: [],
            tripParams: null,
            tripDetailsRow: null,
            fields: [
                {date: {label: this.$t('delivery_note.date'), sortable: true}},
                {id: {label: this.$t('common.id'), sortable: true}},
                {vehicle: {label: this.$t('delivery_note.vehicle'), sortable: true}},
                {company: {label: this.$t('daily_summary.company'), sortable: true}},
                {material: {label: this.$t('delivery_note.material'), sortable: true}},
                {type_test_code: {label: this.$t('material_station.material.type_test_code'), sortable: true}},
                {station: {label: this.$t('material_station.title'), sortable: true}},
                {station_company: {label: this.$t('material_station.station_owner'), sortable: true}},
                {amount: {label: this.$t('delivery_note.amount'), sortable: true}},
                {unit: {label: this.$t('delivery_note.unit')}},
                {show_unload_records: {label: this.$t('contract_reports.unload_records.title')}}
            ]
        }
    },
    watch: {
        searchParams: function () {
            this.fetchTrips()
        }
    },
    created() {
        this.fetchTrips()
    },
    methods: {
        fetchTrips: function () {
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                this.trips = []
                this.$emit('loading', true)
                this.tripParams = {
                    to: this.getDateIsoString(new Date(this.searchParams.dateTo)),
                    from: this.getDateIsoString(new Date(this.searchParams.dateFrom)),
                    contract: this.searchParams.contract
                }
                this.restFetchParams(this.contractDailyTripSummaryUrl, this.tripParams, this.handleTripSuccess, this.tripsFail)
            }
        },
        handleTripSuccess: function (response) {
            this.$emit('loading', false)
            this.trips = response.data
            this.$emit('tripsSuccess', this.trips)
        },
        tripsFail: function () {
            this.$emit('loading', false)
        },
        tripsLoading(loading) {
            this.$emit('loading', loading)
        },
        showTripDetails: function (selectedRow){
            this.tripDetailsRow = selectedRow
        },
    }
}
</script>
