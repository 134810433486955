<template>
    <div>
        <div class="pl-2 pr-2">
            <b-row class="nopads">
                <div class="col-sm-12 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_type') }}</span>
                        <b-form-group class="mb-0" label-for="value">
                            <b-form-select
                                v-model="item.type"
                                :state="!!item.type"
                                size="sm"
                            >
                                <template slot="first">
                                    <option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t('road_sign.select_sign_type') }}
                                    </option>
                                </template>
                                <option
                                    v-for="type in getRoadSignTypes()"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-12 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.road_sign') }}</span>
                    <multiselect
                        :class="[!item.sign ? 'validationError' : '']"
                        v-model="item.sign"
                        :placeholder="$t('road_sign.select_sign')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="true"
                        :multiple="false"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="signs"
                        >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                            <b-row class="nopads">
                                <b-col cols="auto" class="nopads">
                                    <img class="option__image"
                                            :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="25px"
                                            alt="">
                                </b-col>
                                <b-col style="padding-top: 2px;">
                                    <span class="option__title" style="font-weight: 400">{{
                                            props.option.name
                                        }}</span>
                                </b-col>
                            </b-row>
                        </template>
                        <template slot="option"
                                    slot-scope="props">
                            <b-row class="nopads">
                                <b-col cols="auto" class="nopads">
                                    <img class="option__image"
                                            :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="35px"
                                            alt="">
                                </b-col>
                                <b-col class="pt-2">
                                    <span class="option__title pt-2">{{ props.option.name }}</span>
                                </b-col>
                            </b-row>
                        </template>

                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_value') }}</span>
                    <b-form-group
                        label-for="value"
                    >
                        <b-form-input
                            id="value"
                            v-model="item.value"
                            type="number"
                            :placeholder="$t('road_sign.value_hint')"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.road_direction') }}</span>
                    <b-form-group
                        label-for="value"
                    >
                        <b-form-input
                            id="value"
                            max="3"
                            min="1"
                            v-model="item.direction"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_lane') }}</span>
                    <b-form-group
                        label-for="value"
                    >
                        <b-form-input
                            id="value"
                            min="1"
                            v-model="item.lane"
                            type="number"
                            size="sm"
                            />
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.installation') }}</span>
                    <b-form-group label-for="value">
                        <b-form-select
                            v-model="item.installation"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('road_sign.select_installation') }}
                                </option>
                            </template>
                            <option
                                v-for="installation in getVelhoInstallations()"
                                :key="installation.value"
                                :value="installation.value"
                            >
                                {{ installation.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.side') }}</span>
                    <b-form-group label-for="value">
                        <b-form-select
                            v-model="item.side"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('road_sign.select_side') }}
                                </option>
                            </template>
                            <option
                                v-for="side in getSides()"
                                :key="side.value"
                                :value="side.value"
                            >
                                {{ side.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.material') }}</span>
                    <b-form-group label-for="value">
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('road_sign.select_material') }}
                                </option>
                            </template>
                            <option
                                v-for="material in getMaterials()"
                                :key="material.value"
                                :value="material.value"
                            >
                                {{ material.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_direction') }}</span>
                    <b-form-group label-for="value">
                        <b-form-input
                            id="value"
                            min="0"
                            max="359"
                            v-model="item.sign_direction"
                            type="number"
                            :placeholder="$t('road_sign.direction_hint')"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <condition-selector v-model="item.sign_condition"/>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.additional_type') }}</span>
                    <b-form-group label-for="value">
                        <b-form-select
                            v-model="item.additional_type"
                            :state="!!item.additional_type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('road_sign.select_additional_type') }}
                                </option>
                            </template>
                            <option
                                v-for="type in getSignAdditionalType()"
                                :key="type.value"
                                :value="type.value"
                            >
                                {{ type.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads" >
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.order_number') }}</span>
                    <b-form-group
                        label-for="orderNumber"
                    >
                        <b-form-input
                            id="orderNumber"
                            min="0"
                            v-model="item.order_number"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4 pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_width') }}</span>
                    <b-form-group
                        label-for="signWidth"
                    >
                        <b-form-input
                            id="signWidth"
                            min="0"
                            v-model="item.width"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_height') }}</span>
                    <b-form-group
                        label-for="signHeight"
                    >
                        <b-form-input
                            id="signHeight"
                            min="0"
                            v-model="item.height"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4 nopads pr-1 pl-1">
                    <span class="span-title">{{ $t('road_sign.sign_color') }}</span>
                    <b-form-group
                        label-for="signColor"
                    >
                        <b-form-select
                            id="signColor"
                            v-model="item.sign_color"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('road_sign.select_sign_color') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getVelhoSignColors()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor ref="equipmentLocationEditor" :hideSideSelector="true" :item="item"/>
                    </div>
                </b-row>
            <b-row class="nopads">
                <span class="span-title">{{ $t('road_sign.info') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="item.info"
                    :placeholder="$t('road_sign.info')"
                    :rows="4"
                    :max-rows="8"
                />
            </b-row>
        </div>
    </div>
</template>

<script>
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";

export default {
    name: 'RoadSignEditor',
    components: { ConditionSelector, VelhoEquipmentLocationDetailsEditor },
    mixins: [roadSignHelper, conversionModelHelper, restApi, velhoHelper],
    props: {
        item: {
            type: Object
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    watch: {
        'item.type': function () {
            if (this.item.type === null) {
                this.signs = []
            } else {
                this.signs = this.getSigns(this.item.type)
            }
            delete this.item.sign;
        }
    },
    data() {
        return {
            signs: []
        }
    },
    methods: {
        isValidForm: function() {
            let isFormValid = true;
            if(!this.item) { return false }
            if(this.item) {
                if(this.item.sign == null) { return false }
                if(this.item.additional_type == null) { return false }
            }
            if(this.$refs.equipmentLocationEditor && !this.$refs.equipmentLocationEditor.isValidForm()) {
                isFormValid = false;
            }
            return isFormValid
        }
    }
}
</script>

<style scoped>
/* Multiselect doesn't support state validation */
.validationError {
    border-color: #dc3545;
}
</style>

