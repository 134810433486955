<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12"
    >
        <div
            class="col-sm-12 list-content result-table"
        >
            <h5 class="ml-2 mt-1 mb-1"><b>{{ contract ? contract.contract_name : "" }}</b></h5>
            <b-row class="nopads">
                <b-col cols="2" class="header-title"> {{ $t('orders.contractor') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contractor_name : "" }}</b-col>
            </b-row>
            <b-row class="nopads mb-2">
                <b-col cols="2" class="header-title"> {{ $t('contracts.number') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contract_number : "" }}</b-col>
            </b-row>
            <b-row class="nopads mb-2">
                <b-col cols="2" class="header-title"> {{ $t('contracts.worksite_key') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contract_work_key : "" }}</b-col>
            </b-row>
            <b-table
                id="resultTable"
                ref="resultTable"
                class="invoice-table border-0"
                :small="true"
                :responsive="true"
                :items="tableItems"
                :fields="fields"
                @row-clicked="showDetails"
                hover
                bordered
            >
                <template slot="empty">
                    <div class="text-center my-2">
                        {{ $t('area_report.no_results') }}
                    </div>
                </template>
                <template v-slot:bottom-row="row">
                    <td :colspan="7" style="font-weight: bold; text-transform: uppercase">
                        <span class="left"><b>{{ $t('common.total') }}</b></span>
                    </td>
                    <td style="font-weight: bold; text-transform: uppercase">{{ costCombined ? parseFloat(costCombined).toFixed(2) : "" }}</td>
                </template>
                <template v-slot:cell(task_type)="row">
                    {{ row.item.order.task_type.name }}
                </template>
                <template v-slot:cell(executors)="row">
                    <div v-if="row.item.order.executors && row.item.order.executors.length">
                    <span v-for="(executor, index) in row.item.order.executors" :key="index">
                        {{ executor.company.name }}<span v-if="index < row.item.order.executors.length - 1">, </span>
                    </span>
                    </div>
                    <div v-else>
                        {{ $t('orders.no_executors') }}
                    </div>
                </template>
                <template v-slot:cell(time)="row">
                    {{
                        (row.item.start_date ? timestampToDateInput(row.item.start_date) : $t('common.unknown'))
                        + " - " +
                        (row.item.end_date ? timestampToDateInput(row.item.end_date) : $t('common.unknown'))
                    }}
                </template>
                <template v-slot:cell(info)="row">
                    {{ row.item.order.info }}
                </template>
                <template v-slot:cell(amount)="row">
                    {{ parseFloat(row.item.amount).toFixed(2) }}
                </template>
                <template v-slot:cell(pricing_model)="row">
                    {{ row.item.pricing_model ? pricingModelToText(row.item.pricing_model, row.item.price_list) : "-" }}
                </template>
                <template v-slot:cell(unit_price)="row">
                    {{ row.item.unit_price ? (row.item.unit_price / 100).toFixed(3) : null }}
                </template>
                <template v-slot:cell(cost)="row">
                    {{ parseFloat(row.item.cost).toFixed(2) }}
                </template>
            </b-table>
        </div>
        <div class="text-right mt-3">
            <button
                class="btn btn-success large"
                @click.stop="acceptInvoicing"
            >
                {{ $t('work_permission.accept') }}
            </button>
        </div>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {priceHelper} from '@/components/mixins/PricingMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'InvoicingContractTable',
    mixins: [timeUtils, workManagementHelper, priceHelper],
    props: {
        contract: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'task_type',
            sortDesc: false,
            tableItems: [],
            loading: false
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        contract: function () {
            this.initTableItems()
        }
    },
    computed: {
        costCombined: function () {
            let result = 0
            if(this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.contract.orders.forEach(order => {
                    result += order.cost
                })
            }
            return result
        },
        fields() {
            return [
                {key: 'task_type', label: this.$t('orders.task_type'), sortable: false},
                {key: 'executors', label: this.$t('orders.executor'), sortable: false},
                {key: 'time', label: this.$t('areas.time'), sortable: false},
                {key: 'info', label: this.$t('common.info'), sortable: false},
                {key: 'amount', label: this.$t('contracts.amount'), sortable: false},
                {key: 'pricing_model', label: this.$t('contracts.unit'), sortable: false},
                {key: 'unit_price', label: this.$t('additional_cost.unit_price'), sortable: false},
                {key: 'cost', label: this.$t('daily_summary.cost'), sortable: false},
            ]
        },
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.contract.orders.forEach(item => {
                    this.tableItems.push(item)
                })
            }
        },
        acceptInvoicing() {
            if(this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.$emit('acceptInvoicing', this.contract)
            }
        },
        showDetails: function (item) {
            if (item) {
                this.$emit('showDetails', item)
            }
        }
    }
}
</script>

<style>
.header-title {
    font-weight: bold;
    font-size: .9em;
    color: #90b677;
    padding: 0;
    margin: 0;
    margin-right: 1em;
    padding-left: .5em;
}

.invoice-table {
    border-collapse: collapse;
}

.invoice-table td,
.invoice-table th {
    font-size: .9em;
    border: none;
    border-bottom: 1px solid #dee2e6;
}

.invoice-table thead th div {
    font-weight: bold;
}

</style>
