<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="list-content result-table">
        <b-table
        small
        id="resultTable"
        ref="resultTable"
        striped
        :items="tripItems"
        :fields="fields"
        @row-clicked="(item) => $emit('row-clicked', item.id)"
    >
        <template v-slot:cell(startTime)="row">
            {{ toLocalTime(row.value) }}
        </template>
        <template v-slot:cell(endTime)="row">
            {{ toLocalTime(row.value) }}
        </template>
        <template v-slot:cell(tripnote)="row">
                        <span @click.stop="" v-if="row.item.tripnotes && row.item.tripnotes.length > 0">
                            <b-tooltip :target="'notes'+row.item.id" triggers="hover" placement="left">
                                <span
                                    v-for="tripNote in row.item.tripnotes"
                                    v-bind:key="tripNote.id">
                                    <span class="tooltip-title">{{ toLocalTime(tripNote.start_time) }}-{{ toLocalTime(tripNote.end_time) }}</span>
                                    {{ tripNote.note }}
                                </span>
                            </b-tooltip>
                            <font-awesome-icon
                                :id="'notes'+row.item.id"
                                icon="comment"
                                class="note"
                            />
                        </span>
        </template>
        <template v-slot:cell(csv)="row">
            <b-tooltip :target="'coord_'+row.item.id" triggers="hover"  placement="left">
                {{ $t('observations.download_coordinates') }}
            </b-tooltip>
            <font-awesome-icon
                :id="'coord_'+row.item.id"
                icon="file-csv"
                class="row-icon"
                style="color: #4AAE9B"
                @click.stop="$emit('downloadGeometry', row.item.id)"
            />
        </template>
        <template v-slot:cell(geojson)="row">
            <b-tooltip :target="'coord_'+row.item.id" triggers="hover"  placement="left">
                {{ $t('trip.download_geojson') }}
            </b-tooltip>
            <font-awesome-icon
                :id="'coord_'+row.item.id"
                icon="map"
                class="row-icon"
                style="color: #4AAE9B"
                @click.stop="$emit('downloadGeoJson', row.item.id)"
            />
        </template>
    </b-table>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'TripList',
    mixins: [timeUtils, vehicleHelper],
    props: {
        isMapViewer: {
            type: Boolean,
            default: () => false
        },
        trips: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            loading: 0,
        }
    },
    computed: {
        fields() {
            if (!this.isMapViewer) {
                return [
                    {
                        key: 'company',
                        label: this.$t('users.company'),
                        sortable: true
                    },
                    {
                        key: 'driver',
                        label: this.$t('trip_list.driver'),
                        sortable: true
                    },
                    {
                        key: 'vehicle',
                        label: this.$t('trip_list.vehicle'),
                        sortable: true
                    },
                    {
                        key: 'mode',
                        label: this.$t('trip_list.mode'),
                        sortable: true
                    },
                    {
                        key: 'customer',
                        label: this.$t('contracts.customer'),
                        sortable: true
                    },
                    {
                        key: 'contract',
                        label: this.$t('orders.contract'),
                        sortable: true
                    },
                    {
                        key: 'order',
                        label: this.$t('vehicle_position.order'),
                        sortable: true
                    },
                    {
                        key: 'startTime',
                        label: this.$t('trip_list.start_time'),
                        sortable: true
                    },
                    {
                        key: 'startRoad',
                        label: this.$t('trip_list.start_loc'),
                        sortable: true
                    },
                    {
                        key: 'endTime',
                        label: this.$t('trip_list.end_time'),
                        sortable: true
                    },
                    {
                        key: 'endRoad',
                        label: this.$t('trip_list.end_loc'),
                        sortable: true
                    },
                    {
                        key: 'duration',
                        label: this.$t('trip_list.duration') + ' (H:mm)',
                        sortable: true
                    },
                    {
                        key: 'length',
                        label: this.$t('trip_list.trip_length'),
                        sortable: true
                    },
                    {
                        key: 'avg_speed',
                        label: this.$t('trip_list.avg_speed'),
                        sortable: true
                    },
                    {
                        key: 'delivery',
                        label: this.$t('contract_reports.unload_records.delivery_id'),
                        sortable: true
                    },
                    {
                        tripnote: {
                            label: this.$t('daily_summary.trip_notes'),
                            sortable: false
                        }
                    },
                    {
                        key: 'csv',
                        label: "",
                        sortable: false
                    },
                    {
                        key: 'geojson',
                        label: "",
                        sortable: false
                    }
                ]
            }
            return [
                {
                    key: 'vehicle',
                    label: this.$t('trip_list.vehicle'),
                    sortable: true
                },
                {
                    key: 'mode',
                    label: this.$t('trip_list.mode'),
                    sortable: true
                },
                {
                    key: 'contract',
                    label: this.$t('orders.contract'),
                    sortable: true
                },
                {
                    key: 'order',
                    label: this.$t('vehicle_position.order'),
                    sortable: true
                },
                {
                    key: 'startTime',
                    label: this.$t('trip_list.start_time'),
                    sortable: true
                },
                {
                    key: 'startRoad',
                    label: this.$t('trip_list.start_loc'),
                    sortable: true
                },
                {
                    key: 'endTime',
                    label: this.$t('trip_list.end_time'),
                    sortable: true
                },
                {
                    key: 'endRoad',
                    label: this.$t('trip_list.end_loc'),
                    sortable: true
                },
                {
                    key: 'duration',
                    label: this.$t('trip_list.duration') + ' (H:mm)',
                    sortable: true
                },
                {
                    key: 'length',
                    label: this.$t('trip_list.trip_length'),
                    sortable: true
                },
                {
                    key: 'avg_speed',
                    label: this.$t('trip_list.avg_speed'),
                    sortable: true
                }
            ]
        },

        tripItems() {
            return this.trips.map((trip) => {
                const duration_min = this.getDurationInMinutes(trip.start_time, trip.end_time);
                const duration = this.getWorkTime(duration_min);
                return {
                    id: trip.id,
                    company: trip.company_name || '-',
                    driver: trip.driver,
                    vehicle: this.isMapViewer ? this.getVehicleTypeName(trip.vehicle_type) : trip.vehicle,
                    mode: this.getTripModeString(trip.mode),
                    customer: trip.customer || '-',
                    contract: trip.contract || '-',
                    order: trip.order || '-',
                    startTime: trip.start_time,
                    endTime: trip.end_time,
                    startRoad: trip.start_road,
                    endRoad: trip.end_road,
                    length: parseFloat(trip.length < 10 ?
                        trip.length.toFixed(3) :
                        trip.length.toFixed(1)
                    ).toLocaleString(),
                    duration,
                    avg_speed: this.getAverageSpeed(trip.length, duration_min),
                    delivery: trip.delivery_amount ? trip.delivery_amount + ' ' + trip.delivery_unit : '-',
                    tripnotes: trip.tripnotes
                }
            })
        }
    }
}
</script>
