<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12">
        <div
            v-if="canAdd"
            class="data-list-item add mb-2"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="user"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :sort-by="sortBy"
                    :sort-compare-locale="$i18n.locale"
                    :bordered="true"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template
                        v-slot:cell(company)="row"
                    >
                        <span>{{ row.value.name }}</span>
                    </template>
                    <template
                        v-slot:cell(status)="row"
                    >
                        <span>{{ row.value === 1 ? $t('api_keys.active') : $t('api_keys.inactive') }}</span>
                    </template>
                    <template
                        v-slot:cell(type)="row"
                    >
                        <span>{{ getTypeText(row.value) }}</span>
                    </template>

                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'ApiKeysList',
    mixins: [],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        canAdd: Boolean
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: false,
            fields: [
                {key: 'id', label: this.$t('api_keys.id'), sortable: true},
                {key: 'company', label: this.$t('api_keys.company'), sortable: true},
                {key: 'type', label: this.$t('api_keys.type'), sortable: true},
                {key: 'purpose', label: this.$t('api_keys.purpose'), sortable: true},
                {key: 'status', label: this.$t('api_keys.status'), sortable: true },
                {key: 'api_key', label: this.$t('api_keys.key'), sortable: true},
            ],
            loading: false
        }
    },
    methods: {
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        },
        getTypeText(value) {
            switch (value) {
                case 1: return this.$t('api_keys.type_contract')
                case 2: return this.$t('api_keys.type_delivery')
                case 3: return this.$t('api_keys.type_gis')
                case 4: return this.$t('api_keys.type_ticket')
                case 5: return this.$t('api_keys.type_spreader')
            }
            return this.$t('common.unknown')
        }
    }
}
</script>
