<template>
    <div class="nopads">
        <b-row class="nopads">
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.road_number') }}</span>
                <b-form-input
                    v-model="item.road_num"
                    type="number"
                    min="0"
                    size="sm"
                    :state="item.road_num != '' && item.road_num != null"
                />
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.road_section_number') }}</span>
                <b-form-input
                    v-model="item.road_sec"
                    type="number"
                    min="0"
                    size="sm"
                    :state="item.road_sec != '' && item.road_sec != null"
                />
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.distance') }}</span>
                <b-form-input
                    v-model="item.distance"
                    type="number"
                    min="0"
                    size="sm"
                    :state="item.distance != '' && item.distance != null"
                />
            </div>
        </b-row>
        <b-row class="nopads">
            <div v-if="!this.hideTracksSelector" class="col-sm-6 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_conversion.tracks') }}</span>
                <b-form-select size="sm" v-model="item.location_specification.tracks" :state="this.item.location_specification.tracks != null">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.select_equipment_track') }}
                        </option>
                    </template>
                    <option
                        v-for="option in tracksSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
            <div v-if="!this.hideSideSelector" class="col-sm-6 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('road_sign.side') }}</span>
                <b-form-select size="sm" v-model="item.location_specification.side" :state="this.item.location_specification.side != null">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.select_equipment_side') }}
                        </option>
                    </template>
                    <option
                        v-for="option in sideSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
        </b-row>
    </div>
</template>

<script>
import {velhoHelper, side, tracks} from "@/components/mixins/VelhoMixin";

export default {
    name: 'velho-equipment-location-details-editor',
    mixins: [velhoHelper],
    components: {},
    props: {
        item: {
            type: Object,
            default: null
        },
        hideSideSelector: {
            type: Boolean,
            default: false
        },
        hideTracksSelector: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        sideSelector() {
            return  Object.values(side).map(value => {
                return {
                    text: this.getVelhoSideString(value),
                    value: value
                }
            });

        },
        tracksSelector() {
            return  Object.values(tracks).map(value => {
                return {
                    text: this.getVelhoTrackString(value),
                    value: value
                }
            });
        }
    },
    data() {
        return {}
    },
    methods: {
        isValidForm: function() {
            let isFormValid = true;
            if(!this.item) { isFormValid = false }
            if(this.item) {
                if(this.item.road_num == '' || this.item.road_sec == null) { return false }
                if(this.item.road_sec == '' || this.item.road_sec == null) { return false }
                if(this.item.distance == '' || this.item.distance == null) { return false }
                if(!this.hideTracksSelector && this.item.location_specification.tracks == null) { return false }
                if(!this.hideSideSelector && this.item.location_specification.side == null) { return false }
            }
            
            return isFormValid
        }
    }
}
</script>