<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('gate.editor_title')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <road-addess-view :road-number="item.road_num" :section-number="item.road_sec" :distance="item.distance" :show-street-name="false"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('gate.material') }}</span>
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                        >
                            <option
                                v-for="item in getGateMaterials()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('gate.opening_mechanism.title') }}</span>
                        <b-form-select
                            v-model="item.opening_mechanism"
                            size="sm"
                        >
                            <option
                                v-for="item in getOpeningMechanisms()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition"/>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('portal.code') }}</span>
                        <b-form-input
                            id="height"
                            min="1"
                            v-model="item.code"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('gate.width') }}</span>
                        <b-form-input
                            id="width"
                            v-model="item.width"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('gate.height') }}</span>
                        <b-form-input
                            id="height"
                            v-model="item.height"
                            type="number"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor ref="equipmentLocationEditor" :item="item" />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-damages :items="item.equipment_damages"/>
                    </div>
                </b-row>
                <equipment-actions v-if="!this.isAddEquipmentMode" ref="actions" v-model="item.actions" />
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="extGatesUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import {gateHelper} from "@/components/mixins/GateMixin";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";
import RoadAddessView from "@/components/velho/RoadAddressView";

export default {
    name: 'PortalEditor',
    components: {
        RoadAddessView,
        LocationDeviation, VelhoEquipmentLocationDetailsEditor, VelhoEquipmentDamages,
        LocationDetails, EquipmentActions, ConditionSelector, VelhoEditorButtons, LinkedEquipment},
    mixins: [mapHelper, conversionModelHelper, restApi, velhoHelper, gateHelper],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },
        isValidForm: function() {
            let isFormValid = true;
            if(!this.item) { return false }
            if(this.item) {
                if(this.item.opening_mechanism == null) { return false }
            }
            if(this.isAddEquipmentMode && this.$refs.equipmentLocationEditor && !this.$refs.equipmentLocationEditor.isValidForm()) {
                isFormValid = false;
            }
            return isFormValid
        }
    }
}
</script>

<style scoped>
</style>

