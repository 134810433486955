<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <div class="nopads"
             v-for="(contract, key) in results"
             :key="key">
            <InvoicingContractTable
                :contract="contract"
                @showDetails="showDetails"
                @acceptInvoicing="acceptInvoicing"
            />
            <hr class="report-divider ml-2 mr-2">
        </div>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import InvoicingContractTable from "@/components/invoice/InvoicingContractTable.vue";
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'InvoicingList',
    mixins: [timeUtils, workManagementHelper],
    components: {InvoicingContractTable},
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        showDetails: function (event) {
            this.$emit('showDetails', event)
        },
        acceptInvoicing: function (item) {
            this.$emit('acceptInvoicing', item)
        }
    }
}
</script>
