<template>
    <div class="col-sm-12 nopads">
        <b-modal
            class="col-sm-12 editor-form"
            ref="harjaModal"
            centered
            hide-footer
            body-class="fill-modal"
            :title="getTitle(harjaType)"
            id="harjaPutModal"
            @hidden="freetext = null"
        >
        <div class="col-sm-12 form-data">
                <div class="col-sm-12 nopads">
                    <span class="span-title">{{ $t('harja.free_text') }}</span>
                    <b-form-textarea
                        id="areaName"
                        v-model="freetext"
                        type="text"
                        rows="3"
                        size="sm"
                    />
                </div>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="result-button"
                    @click.stop="hideModal"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    :disabled="loading"
                    @click.stop="postHarjaKuittaus"
                >
                    {{ $t('common.send') }}
                </b-button>

            </div>

        </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </b-modal>

    </div>
</template>

<script>

import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {EventBus} from "@/event-bus";

export default {
    name: 'HarjaSendModal',
    mixins: [restApi, timeUtils],
    components: {},
    props: {
        harjaType: {
            type: String,
            return: null,
        },
        harjaId: {
            type: Number,
            return: null,
        },
        contract: {
            type: Number,
            return: null,
        },
    },
    data() {
        return {
            loading: false,
            freetext: null
        }
    },
    methods: {
        hideModal: function () {
            this.freetext = null
            this.$bvModal.hide("harjaPutModal")
        },
        getTitle: function (key) {
            switch (key) {
                case 'vastaanotto': return this.$t('harja.receive')
                case 'aloitus': return this.$t('harja.start')
                case 'muutos': return this.$t('harja.change')
                case 'lopetus': return this.$t('harja.end')
                case 'vastaus': return this.$t('harja.answer')
            }
            return ' '
        },
        async postHarjaKuittaus() {
            let params = {}
            params.freetext = this.freetext
            params.receipt = this.harjaType
            params.contract = this.contract
            
            // eslint-disable-next-line
            console.log("Sending with params", params)

            this.loading = true
            this.restUpdate(this.harjaAlertUrl + '/' + this.harjaId, params, this.putSuccess, this.putFailed);
        },
        close: function () {
            this.$emit('close')
        },
        putSuccess: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
            this.$bvModal.hide("harjaPutModal")
        },
        putFailed: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
    }
}
</script>

<style scoped>

</style>
