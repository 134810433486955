<template>
    <div v-if="locationDeviation" class="pl-3 pr-3">
        <span class="span-title">{{ $t('equipments.location_deviation') }}</span>
        <span class="item-text">{{ getLocationDeviationString() }}</span>
    </div>
</template>

<script>

export default {
    name: 'LocationDeviation',
    props: {
        locationDeviation: {
            type: Number,
            default: null
        }
    },
    methods: {
        getLocationDeviationString() {
            switch (this.locationDeviation) {
                case 1: return this.$t('equipments.deviation_1')
                case 2: return this.$t('equipments.deviation_2')
                case 3: return this.$t('equipments.deviation_3')
                case 4: return this.$t('equipments.deviation_4')
                case 5: return this.$t('equipments.deviation_5')
                case 6: return this.$t('equipments.deviation_6')
                case 7: return this.$t('equipments.deviation_7')
            }
            return this.$t('common.unknown')
        }
    }
}
</script>

<style scoped>
</style>

