<template>
    <div class="nopads">
        <span class="span-title">{{ $t('road_sign.condition') }}</span>
        <b-form-select
            v-model="condition"
            size="sm"
            :disabled="disabled"
        >
            <template slot="first">
                <option
                    :value="null"
                    disabled
                >
                    {{ $t('road_sign.select_condition') }}
                </option>
            </template>
            <option
                v-for="item in getConditions()"
                :key="item.value"
                :value="item.value"
            >
                {{ item.name }}
            </option>
        </b-form-select>
    </div>
</template>

<script>

import {condition} from "@/components/mixins/RoadSignMixin";

export default {
    name: 'condition-selector',
    props: {
        value: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            condition: null
        }
    },
    watch: {
        condition: function (val) {
            this.$emit('input', val)
        }
    },
    mounted() {
        this.condition = this.value
    },
    methods: {
        getConditions: function () {
            return [
                {name: this.$t('road_sign.condition_poor'), value: condition.POOR},
                {name: this.$t('road_sign.condition_mediocre'), value: condition.MEDIOCRE},
                {name: this.$t('road_sign.condition_satisfactory'), value: condition.SATISFACTORY},
                {name: this.$t('road_sign.condition_good'), value: condition.GOOD},
                {name: this.$t('road_sign.condition_excellent'), value: condition.EXCELLENT},
            ]
        },
    }
}
</script>
<style>
</style>
