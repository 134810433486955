<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <div v-if="results && results.length > 0" class="col-12 text-right">
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                @click.stop="showAll()"
            >
                {{ $t('trip.show_map') }}
            </b-button>
        </div>
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(time)="row">
                        {{ toLocalTime(row.item.start_time) }} -  {{ toLocalTime(row.item.end_time) }}
                    </template>
                    <template v-slot:cell(vehicle)="row">
                        {{ row.value.license_plate }} {{ row.value.make }} {{ row.value.vehicle_model }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(dry_material)="row">
                        {{ getMaterialName(row.item.material_dry) }}
                    </template>
                    <template v-slot:cell(dry_amount)="row">
                        {{ row.item.total_dry_amount }} {{ row.item.material_dry_unit }}
                    </template>
                    <template v-slot:cell(liquid_material)="row">
                        {{ getMaterialName(row.item.material_liquid) }}
                    </template>
                    <template v-slot:cell(liquid_amount)="row">
                        {{ row.item.total_liquid_amount }} {{ row.item.material_liquid_unit }}
                    </template>
                    <template v-slot:cell(map)="row">
                        <div class="spreader-data-actions">
                            <font-awesome-icon
                                :id="row.item.id"
                                icon="map"
                                class="row-icon"
                                style="color: #4AAE9B"
                                @click.stop="emitEdit(row.item.id)"
                            />
                            <font-awesome-icon
                                icon="cloud-upload-alt"
                                class="row-icon"
                                style="color: #4AAE9B"
                                @click.stop="emitEdit(row.item.id, true)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import {vehicleHelper} from "@/components/mixins/VehicleMixin";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";

export default {
    name: 'SpreaderDataList',
    mixins: [timeUtils, workManagementHelper, vehicleHelper, spreaderDataMixin],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            fields: [
                {key: 'time', label: this.$t('spreader_data.time'), sortable: true},
                {key: 'vehicle', label: this.$t('trip_list.vehicle'), sortable: true},
                {key: 'spreader_id', label: this.$t('spreader_data.spreader'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
                {key: 'dry_material', label: this.$t('spreader_data.dry_material'), sortable: true},
                {key: 'dry_amount', label: this.$t('spreader_data.dry_amount'), sortable: true},
                {key: 'liquid_material', label: this.$t('spreader_data.liquid_material'), sortable: true},
                {key: 'liquid_amount', label: this.$t('spreader_data.liquid_amount'), sortable: true},
                {key: 'map', label: this.$t('menu.map'), sortable: false}
            ],
            loading: false
        }
    },
    methods: {
        emitEdit: function (result, openHarjaSender = false) {
            let item = this.results.find(item => result === item.id)
            if (item) {
                this.$emit('rowSelected', item, openHarjaSender)
            }
        },
        showAll() {
            this.$emit('showAll')
        }
    }
}
</script>
<style scoped>
.spreader-data-actions {
    display: flex;
    gap: 1rem;
}
</style>
