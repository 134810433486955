<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('boom_barrier_bollard.editor_title')  }}
            </h3>
            <location-deviation v-if="this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <road-addess-view :road-number="item.road_num" :section-number="item.road_sec" :distance="item.distance" :show-street-name="false"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('boom_barrier_bollard.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getBoomBarrierBollardTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.material') }}</span>
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('materials.select_material') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getMaterials()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition"/>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.redefined_type.title') }}</span>
                        <b-form-select
                            v-model="item.refined_type"
                            size="sm"
                        >
                            <option :value="null">
                                {{ $t('boom_barrier_bollard.redefined_type.select_type') }}
                            </option>
                            <option
                                v-for="item in getBoomBarrierBollardRedefinedTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.technique.title') }}</span>
                        <b-form-select
                            v-model="item.technique"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('boom_barrier_bollard.select_technique') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getBoomBarrierBollardTechniques()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.control_type.title') }}</span>
                        <b-form-select
                            v-model="item.control_type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('boom_barrier_bollard.select_control_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getBoomBarrierBollardControlTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.length') }}</span>
                        <b-form-input
                            id="length"
                            v-model="item.length"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('boom_barrier_bollard.railway_level_crossing_boom_additional_type.title') }}</span>
                        <b-form-select
                            v-model="item.railway_level_crossing_boom_additional_type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('boom_barrier_bollard.select_railway_level_crossing_boom_additional_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRailwayLevelCrossingBoomAdditionalTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('portal.code') }}</span>
                        <b-form-input
                            id="code"
                            min="1"
                            v-model="item.code"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.product_name') }}</span>
                        <b-form-input
                            id="product_name"
                            min="1"
                            v-model="item.product_name"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor ref="equipmentLocationEditor" :item="item" />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-damages :items="item.equipment_damages"/>
                    </div>
                </b-row>
                <equipment-actions v-if="!this.isAddEquipmentMode" ref="actions" v-model="item.actions" />
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>

            <div class="button-container col-sm-12" v-if="!this.isAddEquipmentMode">
                <b-button
                    variant="secondary"
                    class="result-button"
                    @click.stop="close"
                >
                    {{ $t('common.close') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    :disabled="loading"
                    @click.stop="handleSave(extBoomsBarriersBollardsUrl, item, selectedContract)"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </div>
        <b-modal
            v-if="!this.isAddEquipmentMode"
            id="replace-equipments-modal"
            ref="replaceModal"
            :hide-header=true
            :hide-footer=true
            style="z-index: 100">
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ $t('equipments.replace_title') }}</span>
                <span>{{ $t('equipments.replace_descr') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="$refs.replaceModal.hide()">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="$refs.replaceModal.hide(); handleSubmit(extBoomsBarriersBollardsUrl, item, selectedContract)">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper, equipmentActions} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import {boomBarrierBollardHelper} from "@/components/mixins/BoomBarrierBollardMixin";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";
import RoadAddessView from "@/components/velho/RoadAddressView";

export default {
    name: 'BoomsBarriersBollardsEditor',
    components: {
        RoadAddessView,
        LocationDeviation,
        LocationDetails,
        EquipmentActions,
        ConditionSelector,
        LinkedEquipment,
        VelhoEquipmentLocationDetailsEditor,
        VelhoEquipmentDamages
    },
    mixins: [mapHelper, conversionModelHelper, restApi, velhoHelper, boomBarrierBollardHelper],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSave: function(extBoomsBarriersBollardsUrl, item, selectedContract) {
            if(item.actions.includes(equipmentActions.REPLACE) || item.linked_equipment.some(x => x.hasOwnProperty("actions") && !!x.actions.find(y => y.value === equipmentActions.REPLACE))) {
                this.$refs.replaceModal.show()
            } else {
                this.handleSubmit(extBoomsBarriersBollardsUrl, item, selectedContract)
            }
        },
        handleSubmit: function(extBoomsBarriersBollardsUrl, item, selectedContract) {
            if(item.actions.includes(equipmentActions.REPLACE) || item.linked_equipment.some(x => x.hasOwnProperty("actions") && !!x.actions.find(y => y.value === equipmentActions.REPLACE))) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(extBoomsBarriersBollardsUrl, item, selectedContract)
            }
        },
        close: function () {
            this.$emit('close')
        },
        isValidForm: function() {
            let isFormValid = true;
            if(this.isAddEquipmentMode && this.$refs.equipmentLocationEditor && !this.$refs.equipmentLocationEditor.isValidForm()) {
                isFormValid = false;
            }
            return isFormValid
        }
    }
}
</script>

<style scoped>
</style>

