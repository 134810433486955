<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="pl-2 pr-2"
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-6 pl-1 pr-1">
                        <open-contract-filter
                            :to="toDate"
                            :from="fromDate"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="search-title">{{ $t('contract_diary.start') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <b-form-input
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="search-title">{{ $t('contract_diary.start') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";

export default {
    name: 'ContractDiarySearch',
    components: {OpenContractFilter},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            contract: null,
            fromDate: null,
            toDate: null,
        }
    },
    mounted: function () {
        if (!this.fromDate) {
            this.fromDate = this.timestampToDateInput(this.getMonthStartAsDate().getTime())
        }
    },
    methods: {
        onSubmit: function () {
            var params = {}
            if (this.contract) {
                params.contract = this.contract
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.startDate = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.endDate = this.getEndOfTheDay(this.toDate).getTime()
            }
            this.$emit('search', params)
        },

        setContract(contract) {
            this.contract = contract
        },

        onReset: function () {
            this.contract = null
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>
