<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('users.users_title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <user-list
                    v-if="!editMode"
                    :can-add="true"
                    :results="results"
                    @add="addUser"
                    @edit="editUser"
                />
            </transition>
            <transition name="fade">
                <user-editor
                    v-if="editMode"
                    :is-admin="isAdmin"
                    :user-to-edit="user"
                    :user="userAccount"
                    :phone-mandatory="true"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {userHelper} from '../mixins/UserMixin'
import UserList from './UserList'
import UserEditor from './UserEditor'

export default {
    name: 'Users',
    components: {UserList, UserEditor},
    mixins: [restApi, userHelper],
    data: function () {
        return {
            results: [],
            user: null,
            editMode: false,
            loading: true
        }
    },
    props: {
        userAccount: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isSuperUser: Boolean
    },
    mounted: function () {
        this.fetchUsers()
    },
    methods: {
        fetchUsers: function () {
            this.loading = true
            this.restFetch(this.userUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },

        editUser: function (user) {
            this.user = user
            this.editMode = true
        },

        addUser: function () {
            this.user = this.newUser()
            this.editMode = true
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.fetchUsers()
            this.showList()
        }

    }
}
</script>
